import React from "react";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import { FaLinkedin, FaGithub, FaInstagram } from "react-icons/fa";
import { IoDocumentTextOutline } from "react-icons/io5";



const LeftBanner = () => {
  const [text] = useTypewriter({
    words: ["Front-end Developer", "Back-end Developer", "UI/UX Designer"],
    loop: true,
    deleteSpeed: 10,
    typeSpeed: 20,
    delaySpeed: 2000,
  });
  return (
    <div className="w-1/2 flex flex-col gap-20 mt-20">
      <div className="flex flex-col gap-5">
        <h4 className="text-lg font-normal">WELCOME TO YUVAN'S PORTFOLIO</h4>
        <h1 className="text-6xl font-bold text-white">
          Hi, I'm <span className="text-designColor capitalize">Yuvan</span>
        </h1>
        <h2 className="text-4xl font-bold text-white">
          a <span>{text}</span>
          <Cursor
            cursorBlinking={false}
            cursorStyle="|"
            cursorColor="#66FCF1"
          />
        </h2>
        <p className="text-base font-bodyFont leading-6 tracking-wide">
          Passionate Computer Science student with a focus on technology and
          innovation. Skilled in programming languages and experienced in Data
          Structures, Algorithms, OOP, IoT, and Software Development. I am eager
          to contribute my skills to an organization in the technology sector.
        </p>
      </div>
      <div className="flex justify-between">
        <div>
          <h2 className="text-base uppercase font-titleFont mb-4">
            Find me in
          </h2>
          <div className="flex gap-4">
            <a
              href="https://www.linkedin.com/in/yuvanthirukumaran/"
              className="bannerIcon"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin />
            </a>
            <a
              href="https://github.com/YuvanTec"
              className="bannerIcon"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaGithub />
            </a>
            <a
              href="https://www.instagram.com/itsyuvan.t/"
              className="bannerIcon"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram />
            </a>
          </div>
        </div>
        <div className="flex flex-col">
            <h2 className="text-base uppercase font-titleFont mb-4">MY RESUME</h2>
            <div className="flex gap-4">
                <a href='/path-to-your-resume.pdf' className="bannerIcon" target="_blank" rel="noopener noreferrer">
                    <IoDocumentTextOutline />
                </a>
            </div>
        </div>
      </div>
    </div>
  );
};
export default LeftBanner;
