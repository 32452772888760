import React from 'react';
import Navbar from './components/navbar/Navbar';
import Banner from './components/banner/Banner';
import Project from './components/project/Project';
import Resume from './components/resume/Resume';
import Contact from './components/contact/Contact';

function App() {
    return (
        <div className='w-full h-auto bg-bodyColor text-lightText'>
          <div className='max-w-screen-2xl mx-auto px-16'>
          <Navbar />
          <Banner />
          <Resume />
          <Project />
          <Contact />
          </div>
          
          </div>
           

    );
}

export default App;