import React, { useState } from "react";
import Title from "../layout/Title";
import Work from "./Work";
import Skills from "./skills/Skills";
import Education from "./Education";



const Resume = () => {
    const [workData, setWorkData] = useState(true);
    const [educationData, setEducationData] = useState(false);
    const [skillsData, setSkillsData] = useState(false);

  return (
    <section
      id="resume"
      className="w-full py-20 border-b-[1px] border-b-black">
      <div className="flex justify-center items-center text-center">
        <Title title="4th year student" des="My Resume" />
      </div>

      <div>
        <ul className="w-full grid grid-cols-3">
          <li onClick={()=> setWorkData(true) & setEducationData(false) & setSkillsData(false)}  className={`${
              workData
                ? "border-designColor rounded-lg"
                : "border-transparent"
            } resumeLi`}
          >Work Experience</li>
          <li onClick={()=> setWorkData(false) & setEducationData(true) & setSkillsData(false)} className={`${
              educationData
                ? "border-designColor rounded-lg"
                : "border-transparent"
            } resumeLi`}
             >Education</li>
          <li onClick={()=> setWorkData(false) & setSkillsData(true) & setEducationData(false)} className={`${
              skillsData
                ? "border-designColor rounded-lg"
                : "border-transparent"
            } resumeLi`}
            >Skills</li>
        </ul>
      </div>
      {
        workData && <Work /> 
      }
      {
        educationData && <Education />
      }
      {
        skillsData && <Skills />
      }
      
  
    </section>
  );
}

export default Resume;
