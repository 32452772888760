import React from 'react';
import { YuvanLogo } from '../../assets/index';
import { navLinkdata } from '../../constants';
import { Link } from 'react-scroll';

const Navbar = () => {
    return (
        <div className='w-full h-24 sticky top-0  z-50 bg-bodyColor mx-auto flex justify-between items-center font-titleFont  border-b-[1px] border-b-gray-600'>
            <div className='flex-shrink-0 flex items-center'>
                <img src={YuvanLogo} alt='logo' className='h-96' />
            </div>
            <ul className='flex items-center gap-10 px-16'>
                {navLinkdata.map(({ _id, title, link }) => (
                    <li
                        className='text-base font-normal text-gray-400 tracking-wide cursor-pointer hover:text-designColor duration-300'
                        key={_id}
                    >
                        <Link activeClass='active' to = {link} spy = {true} smooth = {true} offset = {-70} duration={500}> {title} </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Navbar;
