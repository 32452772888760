import React, { useState } from "react";
import Languages from "./Langauges";
import Database from "./Database";
import Frameworks from "./Frameworks";
import Tools from "./Tools";

const Skills = () => {
  const [languagesData, setLanguagesData] = useState(true);
  const [databaseData, setDatabaseData] = useState(false);
  const [frameworksData, setFrameworksData] = useState(false);
  const [toolsData, setToolsData] = useState(false);

  const handleSetActive = (setter) => {
    setLanguagesData(false);
    setDatabaseData(false);
    setFrameworksData(false);
    setToolsData(false);
    setter(true);
  };

  return (
    <div>
      <ul className="w-full pt-10 flex justify-center items-center gap-x-2 text-center">
        <li
          onClick={() => handleSetActive(setLanguagesData)}
          className={`px-2 py-1 cursor-pointer ${
            languagesData
              ? "border-designColor text-designColor rounded-lg"
              : "border-transparent text-gray-500 hover:text-designColor duration-300"
          }`}
        >
          Languages
        </li>
        <span>|</span>
        <li
          onClick={() => handleSetActive(setDatabaseData)}
          className={`px-2 py-1 cursor-pointer ${
            databaseData
              ? "border-designColor text-designColor rounded-lg"
              : "border-transparent text-gray-500 hover:text-designColor duration-300"
          }`}
        >
          Databases
        </li>
        <span>|</span>
        <li
          onClick={() => handleSetActive(setFrameworksData)}
          className={`px-2 py-1 cursor-pointer ${
            frameworksData
              ? "border-designColor text-designColor rounded-lg"
              : "border-transparent text-gray-500 hover:text-designColor duration-300"
          }`}
        >
          Frameworks
        </li>
        <span>|</span>
        <li
          onClick={() => handleSetActive(setToolsData)}
          className={`px-2 py-1 cursor-pointer ${
            toolsData
              ? "border-designColor text-designColor rounded-lg"
              : "border-transparent text-gray-500 hover:text-designColor duration-300"
          }`}
        >
          Tools
        </li>
      </ul>
      {languagesData && <Languages />}
      {databaseData && <Database />}
      {frameworksData && <Frameworks />}
      {toolsData && <Tools />}
    </div>
  );
};

export default Skills;
