import React from 'react';
import ResumeCard from './ResumeCard';
import { motion } from 'framer-motion';

const Education = () => {
    return (
        <motion.div initial={{opacity:0}} animate={{opacity:1, transition:{duration:.5}}} className="py-12 font-titleFont gap-20">
            <p className="test-sm text-designColor tracking-[4px]">2015-2025</p>
            <h2 className="text-4xl font-bold">Education</h2>
        
            <div className="mt-14 w-full h-[500px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
                <ResumeCard
                    title="Incoming Software Engineer Intern"
                    subTitle="Oracle - (July 2024 - Present)"
                    result="GUADALAJARA, MEXICO"
                    des=""
                />
                <ResumeCard
                    title="Software Engineer Intern"
                    subTitle="Cemex - (April 2024 - July 2024)"
                    result="MONTERREY, MEXICO"
                    des={`Developed and implemented a user-friendly web platform tailored for the efficient management of cement data information for administration and staff at Cemex.
Utilized React, Tailwind CSS, and Daisy UI components to develop an intuitive front-end interface.
Leveraged FastAPI for efficient backend development, ensuring robust functionality and high performance.`}
                />
            </div>
        </motion.div>
    );
}

export default Education;