import React from 'react';
import LeftBanner from './LeftBanner';
import { HomeImage } from '../../assets/index';

const Banner = () => {
    return (
        <div
            id='home' 
            className='w-full pt-10 pb-20 flex border-b-black font-titleFont border-b-[1px]'>
                <LeftBanner />

                <div className="w-full lgl:w-1/2 flex justify-center items-start relative">
                    <img
                        className="w-[300px] h-auto lgl:w-[500px] lgl:h-auto z-20 object-cover" // Ensures the image retains its aspect ratio
                        src={HomeImage}
                        alt="HomeImage"
                        style={{ marginTop: '-110px' }} 
                    />
                    <div 
                        className="absolute bottom-0 w-[350px] h-[200px] lgl:w-[500px] lgl:h-[570px] bg-gradient-to-r from-[#1e2024] to-[#202327] shadow-shadowOne flex justify-center items-center"
                        style={{ marginBottom: '-30px', top: '70px' }} 
                    ></div>
                </div>
            </div>
    );
};

export default Banner;
