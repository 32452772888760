import React from "react";
import ProjectCard from "./ProjectCard";
import Title from "../layout/Title";
import { CV01, CV02, CV03} from "../../assets";
import { P01, P02, P03, P04, P05} from "../../assets";
import { Q01, Q02, Q03, Q04, Q05, Q06, Q07, Q08} from "../../assets";
import { I01, I02, I03, I04} from "../../assets";
import {IO01, IO02, IO03, IO04} from "../../assets";
import {M001,M01, M02, M03} from "../../assets";


const Project = () => {
  const projectImages = {
    CVForge: [CV01, CV02, CV03],
    Mypictograms: [P01, P02, P03, P04, P05],
    QuizMaker: [Q01, Q02, Q03, Q04, Q05, Q06, Q07, Q08],
    MULTIAGENTES: [M001,M01, M02, M03],
    ImmunePet: [I01, I02, I03, I04],
    AirConditioner: [IO01, IO02, IO03, IO04],
  };

  return (
    <section id="project" className="w-full py-20 border-b-[1px] border-b-black">
      <div className="flex justify-center items-center text-center">
        <Title title="Welcome to my projects" des="My Projects" />
      </div>
      <div className="grid grid-cols-3 gap-14">
        <ProjectCard
          title="CVForge.ai"
          des="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Explicabo quibusdam voluptate sapiente voluptatibus harum quidem!"
          images={projectImages.CVForge}
        />
        <ProjectCard
          title="Cognitive Android App - Mypictograms"
          des="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Explicabo quibusdam voluptate sapiente voluptatibus harum quidem!"
          images={projectImages.Mypictograms}
        />
        <ProjectCard
          title="Educational Quiz Maker Web Application"
          des="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Explicabo quibusdam voluptate sapiente voluptatibus harum quidem!"
          images={projectImages.QuizMaker}
        />
        <ProjectCard
          title="MULTI-AGENT SYSTEM FOR VEHICLE TRAFFIC CONTROL"
          des="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Explicabo quibusdam voluptate sapiente voluptatibus harum quidem!"
          images={projectImages.MULTIAGENTES}
        />
        <ProjectCard
          title="Immune pet"
          des="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Explicabo quibusdam voluptate sapiente voluptatibus harum quidem!"
          images={projectImages.ImmunePet}
        />
        <ProjectCard
          title="IoT based Air Conditioner Regulator"
          des="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Explicabo quibusdam voluptate sapiente voluptatibus harum quidem!"
          images={projectImages.AirConditioner}
        />
      </div>
    </section>
  );
};

export default Project;
