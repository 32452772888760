import React from "react";
import { contact } from "../../assets/index";
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";


const ContactLeft = () => {
    return(
        <div className="w-full lgl:w-[35%] h-full bg-gradient-to-r from-[#1e2024] to-[#23272b] p-4 lgl:p-8 rounded-lg shadow-shadowOne">
        <img
          className="w-full h-64 object-cover rounded-lg mb-2"
          src={contact}
          alt="contactimg"
        />
        <div className="flex flex-col gap-4">
          <h3 className="text-3xl font-bold text-white">Yuvan Thirukumaran</h3>
          <p className="text-lg font-normal text-gray-400">
            Software Engineer
          </p>
          <p className="text-base text-gray-400 tracking-wide">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit.
            Facilis ipsam autem cumque, accusantium dicta odio.
          </p>
          <p className="text-base text-gray-400 flex items-center gap-2">
            Phone: <span className="text-lightText">+52 8120105131</span>
          </p>
          <p className="text-base text-gray-400 flex items-center gap-2">
            Email:{" "}
            <span className="text-lightText">yuvan.thirukumaran@gmail.com</span>
          </p>
        </div>
        <div className="w-full lgl:w-[60%] flex flex-col justify-between gap-4">
        <h2 className="text-base uppercase font-titleFont mb-4 pt-5">
          Find me in
        </h2>
        <div className="flex gap-4">
          <span className="bannerIcon">
            <FaFacebookF />
          </span>
          <span className="bannerIcon">
            <FaTwitter />
          </span>
          <span className="bannerIcon">
            <FaLinkedinIn />
          </span>
        </div>
      </div>
      </div>
    )
}

export default ContactLeft;
